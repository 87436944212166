<template>
  <div
    v-editable="blok"
    :class="{
      'py-[--blockSpacing] lg:py-0':
        story?.content.component !== 'ContentTypeNewsArticle',
    }"
    class="container px-0 lg:px-5"
  >
    <figure class="c-video w-full">
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="blok.videoType === 'vimeo'" v-html="blok.videoIframe" />
      <!-- eslint-enable -->

      <div
        v-if="blok.videoType === 'storyblok_asset'"
        class="relative aspect-video"
      >
        <UiMediaControl
          v-if="!isPlaying"
          label="Play video"
          icon="play"
          size="lg"
          class="absolute left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 transform"
          @click="play"
        />

        <video
          ref="videoPlayer"
          :controls="isPlaying"
          :poster="blok.poster?.filename"
          playsinline
          class="size-full object-cover"
        >
          <source
            :src="blok.asset?.filename"
            :type="getAssetMimeType(blok.asset?.filename)"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </figure>
  </div>
</template>

<script setup lang="ts">
import type { BlockVideoStoryblok } from '@/types/storyblok'
import { getAssetMimeType } from '@/utils/asset-utils'

defineProps<{ blok: BlockVideoStoryblok }>()

const videoPlayer = ref<HTMLVideoElement | null>(null)
const isPlaying = ref<boolean>(false)
const { story } = storeToRefs(useCurrentStoryStore())

function play() {
  videoPlayer.value?.play()
  isPlaying.value = true
}
</script>

<style scoped lang="postcss">
.c-video :deep(iframe) {
  @apply aspect-video size-full;
}
</style>
